@import "./variables.scss";

.LoginTitle {
    font-size: 32px;
    margin-top: 40px;
}

.LogoImg {
    height: 24px;
}

.LogoImgWrapper {
    width: 100%;
    min-height: 212px;
    max-height: 360px;
    height: calc(100vh - 402px);
}

@media (min-width: 768px) {
    .LogoImg {
        height: 28px;
    }

    .LogoImgWrapper {
        height: 193px;
    }
}
